import React from "react";
import classNames from "classnames";
import loadable from "@loadable/component";
import { useAppState } from "@state";
import { Breadcrumbs } from "@molecules";
import { Container, Text, Button, ButtonAlt, Image, Icon } from "@atoms";

const Video = loadable(() => import("../atoms/Video"));

const PageHero = ({
  backLink,
  button,
  crumbs,
  descriptor,
  heading,
  icon,
  iconColor,
  image,
  noHeight,
  secondaryLink,
  theme,
  watermark,
  video,
}) => {
  const [, dispatch] = useAppState();

  const triggerModal = e => {
    dispatch({
      type: "openModal",
      title: heading,
      description: descriptor,
      // eslint-disable-next-line react/no-unstable-nested-components
      content: React.memo(() => <Video url={video} />),
    });
  };

  const background = {
    pink: "bg-pink",
    purple: "bg-purple",
    darkerTeal: "bg-black",
    darkTeal: "bg-teal-dark",
    teal: "bg-teal",
    lightTeal: "bg-teal-light",
    lighterTeal: "bg-teal-lighter",
    offwhite: "bg-offwhite",
    white: "bg-white",
  };

  const color = {
    darkerTeal: "text-teal-dark/50",
    darkTeal: "text-teal/50",
    teal: "text-teal-light/50",
    lightTeal: "text-teal-lighter/50",
    lighterTeal: "text-teal-lightest/50",
    offwhite: "text-white/50",
    white: "text-offwhite/50",
  }[theme];

  const isDark =
    theme === "pink" ||
    theme === "purple" ||
    theme === "teal" ||
    theme === "darkTeal" ||
    theme === "darkerTeal";

  return (
    <section
      className={classNames(
        "relative z-20 py-10",
        {
          "text-white": isDark,
          "py-20": !noHeight,
          "sm:pt-20 sm:pb-20 lg:pb-28": noHeight,
        },
        background[theme]
      )}
    >
      <Container>
        <div className="flex flex-col items-center gap-10 md:items-start">
          {backLink && !crumbs && (
            <ButtonAlt reverse to={backLink.url} color={theme}>
              {backLink.text}
            </ButtonAlt>
          )}
          {!backLink && crumbs && <Breadcrumbs crumbs={crumbs} color={theme} />}

          {/* text + image row */}
          <div
            className={classNames(
              "relative flex w-full flex-col items-center justify-between gap-10 md:flex-row md:items-start lg:gap-24",
              {
                "sm:min-h-[18rem]": !noHeight,
              }
            )}
          >
            {/* text + watermark container */}
            <div
              className={classNames("relative flex flex-col", {
                "order-2 md:order-1": !icon,
              })}
            >
              <div className="relative z-10 flex flex-shrink-0 flex-col items-center gap-6 text-center md:items-start md:text-left">
                {icon && (
                  <div
                    className={classNames(
                      "h-16 w-16 rounded-full md:h-[5.5rem] md:w-[5.5rem]",
                      background[iconColor]
                    )}
                  >
                    <div
                      className="svg-icon--fit-height flex h-full w-full items-center justify-center p-[20%] text-white"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: icon?.[0]?.code }}
                    />
                  </div>
                )}
                <Text variant={icon ? "h2" : "h1"} className="max-w-xl">
                  {heading}
                </Text>
                {descriptor && (
                  <Text
                    richText
                    className={classNames(
                      "max-w-xl prose-p:text-xl prose-p:font-medium",
                      {
                        "prose-white": isDark,
                      }
                    )}
                  >
                    {descriptor}
                  </Text>
                )}
                {(!!button?.url || !!secondaryLink?.url) && (
                  <div className="flex gap-6">
                    {!!button?.url && (
                      <Button to={button.url}>{button.text}</Button>
                    )}
                    {!!secondaryLink?.url && (
                      <ButtonAlt to={secondaryLink.url} color={color}>
                        {secondaryLink.text}
                      </ButtonAlt>
                    )}
                  </div>
                )}
              </div>
              {watermark && (
                <div className="relative">
                  <div className="pointer-events-none absolute -left-10 -left-16 -top-28 w-[12rem] -rotate-[11.5deg] md:-left-20 md:w-[16rem] lg:-left-40">
                    <Icon
                      name="logoIcon"
                      className={classNames("w-full", color)}
                    />
                  </div>
                </div>
              )}
            </div>

            {/* image container */}
            <div
              className={classNames("relative md:self-start", {
                "order-1 md:order-2": !icon,
                "relative w-[60%] md:-mr-[3rem] md:-mb-[50%] md:-mt-[4rem] md:w-full md:max-w-[30rem] lg:-mr-[7rem]":
                  image,
                "w-48 self-end sm:w-1/3": !image,
              })}
            >
              {image && (
                <div className="relative aspect-square overflow-hidden rounded-full">
                  {video && (
                    <>
                      <button
                        type="button"
                        aria-label="Open video"
                        className="peer absolute inset-0 z-20 flex h-full w-full items-center justify-center"
                        onClick={video ? () => triggerModal() : null}
                      >
                        <div className="flex h-16 w-16 items-center justify-center rounded-full border-4 border-white/80">
                          <Icon
                            name="play"
                            className="z-20 h-12 w-12 text-white/80"
                          />
                        </div>
                      </button>
                      <div className="absolute inset-0 z-10 bg-black/20 mix-blend-multiply duration-500 peer-hover:bg-black/50" />
                    </>
                  )}
                  <Image
                    fill
                    image={image}
                    className="overflow-hidden rounded-full"
                  />
                </div>
              )}
              {!image && !video && (
                <div className="pointer-events-none absolute top-0 -right-[20%] w-full max-w-[16rem] -rotate-[11.5deg] sm:top-4 lg:-right-[40%]">
                  <Icon
                    name="logoIcon"
                    className="w-full text-teal-dark opacity-50"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

PageHero.defaultProps = {
  noHeight: false,
  theme: "darkerTeal",
};

export default PageHero;
